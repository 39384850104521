<template>
  <div id="settings_page">
    <inner-page-title back-path="/" title="Settings"></inner-page-title>

    <div class="container settings-options">
      <span class="settings-menu">Your account</span>

      <!-- Edit profile -->
      <div @click="$router.push('/settings/personal-info').catch(()=>{})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon></div>
        <div class="info-name"><span>Edit profile</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Change password -->
      <div @click="$router.push('/settings/change-password').catch(()=>{})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'unlock-alt']"></font-awesome-icon></div>
        <div class="info-name"><span>Change password</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Change profile photo -->
      <div @click="$router.push('/settings/change-photo').catch(() => {})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'camera']"></font-awesome-icon></div>
        <div class="info-name"><span>Change profile photo</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <span class="settings-menu">Verifications</span>

      <!-- Verify email -->
      <div @click="$router.push({ name: 'profile_verification', query: { verifications: 'EMAIL', direct: true }}).catch(() => {})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon></div>
        <div class="info-name"><span>Verify email</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Verify phone -->
      <div @click="$router.push({ name: 'profile_verification', query: { verifications: 'PHONE', direct: true }}).catch(() => {})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'phone-alt']"></font-awesome-icon></div>
        <div class="info-name"><span>Verify phone</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <span class="settings-menu">Mobility</span>

       <!-- Commuting details -->
      <div @click="$router.push('/settings/commuting-info').catch(()=>{})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'bus']"></font-awesome-icon></div>
        <div class="info-name"><span>Commuting details</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Carpooling details -->
      <div @click="$router.push('/settings/carpooling-info').catch(()=>{})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'car']"></font-awesome-icon></div>
        <div class="info-name"><span>Carpooling details</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Carpooling ticket -->
      <div @click="$router.push('/settings/carpooling-ticket').catch(()=>{})" class="settings-option clickable">
        <div class="icon"><font-awesome-icon :icon="['fas', 'ticket-alt']"></font-awesome-icon></div>
        <div class="info-name"><span>Carpooling parking permit</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <span v-if="false" class="settings-menu">Services</span>

      <!-- Notification Services -->
      <div v-if="false" class="settings-option">
        <div class="icon"><font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon></div>
        <div class="info-name"><span>Allow notifications</span></div>
        <div class="action-icon">
          <el-switch disabled @change="changeNotification" active-color="#ffa140" v-model="notificationsServices"></el-switch>
        </div>
      </div>

      <!-- Location Services -->
      <div v-if="false" class="settings-option">
        <div class="icon"><font-awesome-icon :icon="['fas', 'compass']"></font-awesome-icon></div>
        <div class="info-name"><span>Allow location</span></div>
        <div class="action-icon">
          <el-switch @change="changeLocation" active-color="#ffa140" v-model="locationServices"></el-switch>
        </div>
      </div>

      <span class="settings-menu">More</span>

       <!-- Terms and conditions -->
      <div @click="$router.push('/terms-conditions').catch(()=>{})" class="settings-option clickable">
        <!-- <div class="icon"><font-awesome-icon :icon="['fas', 'bus']"></font-awesome-icon></div> -->
        <div class="info-name"><span>Terms and conditions</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Privacy policy -->
      <div @click="$router.push('/privacy-policy').catch(()=>{})" class="settings-option clickable">
        <!-- <div class="icon"><font-awesome-icon :icon="['fas', 'car']"></font-awesome-icon></div> -->
        <div class="info-name"><span>Privacy policy</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>

      <!-- Safety tips -->
      <div @click="$router.push('/safety-social-tips').catch(()=>{})" class="settings-option clickable">
        <!-- <div class="icon"><font-awesome-icon :icon="['fas', 'car']"></font-awesome-icon></div> -->
        <div class="info-name"><span>Safety & social tips</span></div>
        <div class="action-icon">
          <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
        </div>
      </div>
      
      
      <div @click="logout" class="settings-logout clickable">
        <span>Logout from account</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    notificationsServices: Notification.permission === 'granted',
    locationServices: JSON.parse(localStorage.getItem('locationServicesActive'))
  }),

  methods: {
    logout() {
      this.$store.dispatch('logout').then(result => {
        this.$router.push('/login').catch(()=>{});
      });
    },

    changeNotification() {
      console.log('Changed notifications to ', this.notificationsServices);
    },

    changeLocation(val) {
      if (!this.locationServices) { localStorage.removeItem('locationServicesActive'); };

      if ( navigator.geolocation ) {
        navigator.geolocation.getCurrentPosition((pos) => { localStorage.setItem('locationServicesActive', true); }, (err) => localStorage.removeItem('locationServicesActive'), {
            enableHighAccuracy: false,
            timeout: 15000,
            maximumAge: 0
        });

      } else {
        this.locationServices = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container.settings-options {
    max-width: 600px;
  }
</style>