var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "settings_page" } },
    [
      _c("inner-page-title", {
        attrs: { "back-path": "/", title: "Settings" }
      }),
      _c("div", { staticClass: "container settings-options" }, [
        _c("span", { staticClass: "settings-menu" }, [_vm._v("Your account")]),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router.push("/settings/personal-info").catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["fas", "user"] } })],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push("/settings/change-password")
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "unlock-alt"] }
                })
              ],
              1
            ),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router.push("/settings/change-photo").catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["fas", "camera"] } })],
              1
            ),
            _vm._m(2),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c("span", { staticClass: "settings-menu" }, [_vm._v("Verifications")]),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push({
                    name: "profile_verification",
                    query: { verifications: "EMAIL", direct: true }
                  })
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "envelope"] }
                })
              ],
              1
            ),
            _vm._m(3),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push({
                    name: "profile_verification",
                    query: { verifications: "PHONE", direct: true }
                  })
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "phone-alt"] }
                })
              ],
              1
            ),
            _vm._m(4),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c("span", { staticClass: "settings-menu" }, [_vm._v("Mobility")]),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push("/settings/commuting-info")
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["fas", "bus"] } })],
              1
            ),
            _vm._m(5),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push("/settings/carpooling-info")
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["fas", "car"] } })],
              1
            ),
            _vm._m(6),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router
                  .push("/settings/carpooling-ticket")
                  .catch(function() {})
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "ticket-alt"] }
                })
              ],
              1
            ),
            _vm._m(7),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        false
          ? _c("span", { staticClass: "settings-menu" }, [_vm._v("Services")])
          : _vm._e(),
        false
          ? _c("div", { staticClass: "settings-option" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: ["fas", "bell"] } })],
                1
              ),
              _vm._m(8),
              _c(
                "div",
                { staticClass: "action-icon" },
                [
                  _c("el-switch", {
                    attrs: { disabled: "", "active-color": "#ffa140" },
                    on: { change: _vm.changeNotification },
                    model: {
                      value: _vm.notificationsServices,
                      callback: function($$v) {
                        _vm.notificationsServices = $$v
                      },
                      expression: "notificationsServices"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        false
          ? _c("div", { staticClass: "settings-option" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "compass"] }
                  })
                ],
                1
              ),
              _vm._m(9),
              _c(
                "div",
                { staticClass: "action-icon" },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#ffa140" },
                    on: { change: _vm.changeLocation },
                    model: {
                      value: _vm.locationServices,
                      callback: function($$v) {
                        _vm.locationServices = $$v
                      },
                      expression: "locationServices"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("span", { staticClass: "settings-menu" }, [_vm._v("More")]),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router.push("/terms-conditions").catch(function() {})
              }
            }
          },
          [
            _vm._m(10),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router.push("/privacy-policy").catch(function() {})
              }
            }
          },
          [
            _vm._m(11),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-option clickable",
            on: {
              click: function($event) {
                _vm.$router.push("/safety-social-tips").catch(function() {})
              }
            }
          },
          [
            _vm._m(12),
            _c(
              "div",
              { staticClass: "action-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "settings-logout clickable",
            on: { click: _vm.logout }
          },
          [_c("span", [_vm._v("Logout from account")])]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Edit profile")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Change password")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Change profile photo")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Verify email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Verify phone")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Commuting details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Carpooling details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Carpooling parking permit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Allow notifications")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Allow location")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Terms and conditions")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Privacy policy")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Safety & social tips")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }